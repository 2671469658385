import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";

const AboutPage = () => {
  return (
    <div>
      <Header />
      about page
      <Footer />
    </div>
  );
};

export default AboutPage;
